<div class="container">
  <div fxLayout fxLayoutGap="36px" fxLayoutAlign="flex-start baseline">
    <div class='table-title'>{{('CLIENT_PROMISES_TABLE' | translate) }}</div>

    <form [formGroup]="dateForm">
      <mat-form-field>
        <mat-label>{{ 'CLIENT_STATS_DATE' | translate }}</mat-label>
        <input matInput
               readonly
               formControlName="dateFrom"
               (click)="pickerDateFrom.open()"
               [matDatepicker]="pickerDateFrom"
               [placeholder]="'CLIENT_STATS_DATE' | translate"
        >
        <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerDateFrom disabled="false"></mat-datepicker>
      </mat-form-field>
    </form>
  </div>

  <mat-table [dataSource]="tableData">
    <ng-container matColumnDef="order">
      <mat-header-cell *matHeaderCellDef>{{ 'PROJECT' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{'ID' | translate}}: {{ element.order?.id || '-' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="position">
      <mat-header-cell *matHeaderCellDef>{{ 'POSITION' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.order?.specification?.positionTitle || '-'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="client">
      <mat-header-cell *matHeaderCellDef>{{ 'CLIENT.CLIENT' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.order?.company?.name || element.company?.name || '-' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef>{{ 'DATE' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.date }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="PROMISES_INTRO_CALL_INTERVIEW">
      <mat-header-cell *matHeaderCellDef>{{ 'PROMISES_INTRO_CALL_INTERVIEW' | translate }}</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="element.stats['PROMISES_INTRO_CALL_INTERVIEW'] ? getStatCellView(element.statsData?.offeredLast7days, element.stats['PROMISES_INTRO_CALL_INTERVIEW']) : ''"
      >
        {{ element.statsData?.offeredLast7days || 0 }}/{{
          element.stats['PROMISES_INTRO_CALL_INTERVIEW'] ? element.stats['PROMISES_INTRO_CALL_INTERVIEW'] || 0 : 0}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="PROMISES_PRESENTED_TO_CLIENT">
      <mat-header-cell *matHeaderCellDef>{{ 'PROMISES_PRESENTED_TO_CLIENT' | translate }}</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="element.stats['PROMISES_PRESENTED_TO_CLIENT'] ? getStatCellView(element.statsData?.presentedClient, element.stats['PROMISES_PRESENTED_TO_CLIENT']) : ''"
      >
        {{ element.statsData?.presentedClient || 0 }}/{{
          element.stats['PROMISES_PRESENTED_TO_CLIENT'] ? element.stats['PROMISES_PRESENTED_TO_CLIENT'] || 0 : 0}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="PROMISES_CLIENT_INTERVIEW">
      <mat-header-cell *matHeaderCellDef>{{ 'PROMISES_CLIENT_INTERVIEW' | translate }}</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="element.stats['PROMISES_CLIENT_INTERVIEW'] ? getStatCellView(element.statsData?.toBeInterviewedClient, element.stats['PROMISES_CLIENT_INTERVIEW']) : ''"
      >
        {{ element.statsData?.toBeInterviewedClient || 0 }}/{{
          element.stats['PROMISES_CLIENT_INTERVIEW'] ? element.stats['PROMISES_CLIENT_INTERVIEW'] || 0 : 0}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="PROMISES_HEALTHCHECK">
      <mat-header-cell *matHeaderCellDef>{{ 'PROMISES_HEALTHCHECK' | translate }}</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="element.stats['PROMISES_HEALTHCHECK'] ? getStatCellView(element.statsData?.healthCheckPending, element.stats['PROMISES_HEALTHCHECK']) : ''"
      >
        {{ element.statsData?.healthCheckPending || 0 }}/{{
          element.stats['PROMISES_HEALTHCHECK'] ? element.stats['PROMISES_HEALTHCHECK'] || 0 : 0}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
