import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';

import { StandupDashboardServiceService } from '../standup-dashboard-service.service';

@Component({
  selector: 'app-promises-table',
  templateUrl: './promises-table.component.html',
  styleUrls: ['./promises-table.component.scss']
})
export class PromisesTableComponent implements OnInit {
  dateForm: FormGroup;

  categories: string[] = [
    'PROMISES_INTRO_CALL_INTERVIEW',
    'PROMISES_PRESENTED_TO_CLIENT',
    'PROMISES_CLIENT_INTERVIEW',
    'PROMISES_HEALTHCHECK',
  ];

  categoriesFetch = 'PROMISES_INTRO_CALL_INTERVIEW,PROMISES_PRESENTED_TO_CLIENT,PROMISES_CLIENT_INTERVIEW,PROMISES_HEALTHCHECK';
  statsFetch = [];
  companyFetch = [];

  statsData = [];
  tableData: any[] = [];
  displayedColumns: string[] = [
    'order', 'position', 'client', 'date', ...this.categories
  ];
  rawTableData: any[] = [];
  childDashboardUsers: string[] = [];

  @Input() dashboardId: string;
  @Input() dashboardMembers: any;
  @Input() includeFromChildDashboards: boolean;

  constructor(
    private dashboardService: StandupDashboardServiceService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.dateForm = this.fb.group({
      dateFrom: [moment().startOf('isoWeek').toDate()]
    });
    this.dateForm.get('dateFrom').valueChanges.subscribe(date => {
      this.fetchData(date);
    });

    if (this.includeFromChildDashboards) {
      this.dashboardService.getUsersForCurrentChildDashboards(this.dashboardId).subscribe(resp => {
        if (resp.data.userprofiles.length) {
          this.childDashboardUsers = resp.data.userprofiles.map(profile => profile.id);
        }
        this.fetchData(this.dateForm.get('dateFrom').value);
      });
    } else {
      this.fetchData(this.dateForm.get('dateFrom').value);
    }
  }

  private fetchData(date) {
    this.dashboardService.getPromisesTasks({
      categoriesKeys: this.categoriesFetch,
      owners: this.childDashboardUsers.length ? this.childDashboardUsers : (this.dashboardMembers?.map(member => member.id) || []),
      date_Gte: moment(date).format('YYYY-MM-DD'),
      excludeStatuses: 'DONE'
    }).subscribe(result => {
      if (result.data.tasks.edges.length > 0) {
        const data = result.data.tasks.edges.map(edge => edge.node);
        this.setData(data);
        this.fetchStats(date);
      } else {
        this.tableData = [];
        this.rawTableData = [];
      }
    });
  }

  private fetchStats(date) {
    if (this.statsFetch.length > 0) {
      this.dashboardService.getOrderCandidateStats({
        orderIds: this.statsFetch,
        dateFrom: moment(date).format('YYYY-MM-DD'),
        companyIds: this.companyFetch
      }).subscribe(res => {
        this.statsData = res.data.candidateOrderStats.map(val => ({
          companyId: val.companyId,
          orderId: val.orderId,
          offeredLast7days: val.offeredLast7Days,
          presentedClient: val.presentedClient,
          toBeInterviewedClient: val.toBeInterviewedClient,
          healthCheckPending: val.healthCheckPending
        }));

        this.tableData = this.rawTableData.map(v => ({
          ...v,
          statsData: (v?.order !== null ? this.statsData.find(value => value.orderId === v.order?.id) :
                       this.statsData.find(value => value.companyId === v?.company?.id))
                      || null,
          order: v?.order ? {
            ...v.order,
            id: atob(v.order.id).match(/\d+$/)?.[0] || ''
          } : null
        }));
      });
    }
  }

  private setData(data: any[]): void {
    this.rawTableData = [];
    data.forEach(value => {
      const orderId = value.order?.id;
      const categoryKey = value.category?.key;
      const comment = value.comment || '';

      value = {
        ...value,
        stats: { [categoryKey]: comment }
      };

      if (orderId) {
        const relatedTask = this.rawTableData.find(task => task.order?.id === orderId && task.date === value.date);

        if (relatedTask) {
          relatedTask.stats[categoryKey] = comment;
        } else {
          this.rawTableData.push(value);
        }
      } else {
        this.rawTableData.push(value);
      }
    });

    this.statsFetch = this.rawTableData.map(v => v.order?.id).filter(id => id);
    this.companyFetch = this.rawTableData.map(v => v.company?.id).filter(id => id);
  }

  getStatCellView(currentValue, target): string {
    if (target && !isNaN(Number(target))) {
      const goal = Number(target);

      if ((currentValue || 0) < goal) {
        return 'red';
      } else {
        return 'green-bold';
      }
    }

    return '';
  }
}
