<!-- Dashboard select -->
<div class='dashboard-select' fxLayout fxLayoutAlign='space-between center'>
  <mat-form-field>
    <mat-select placeholder='Select Dashboard' [(value)]='selectedDashboard' (selectionChange)="onDashboardChange($event)">
      <mat-option *ngFor='let dashboard of dashboards' class="table-dashboard-select-option" [value]='dashboard'>
        {{ dashboard.title }}
      </mat-option>
      <div class="dashboard-select-separator"></div>
      <mat-option *ngFor='let dashboard of lvl2dashboards' class="table-dashboard-select-option" [value]='dashboard'>
        {{ dashboard.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="members">
    <app-popup-select-complete
      [selected]="dashboardMembers"
      [addAvailable]="false"
      [removeAvailable]="false"
      [largeSpace]="true"
    ></app-popup-select-complete>
  </div>

  <div class='create-btn-and-links-container'>
    <app-standup-links
      *ngIf='!!selectedDashboardSubject.value'
      [links]='selectedDashboardSubject.value'
      [currentDashboard]='selectedDashboard'
    ></app-standup-links>

    <button
      class="action-btn"
      mat-raised-button
      color="accent"
      [matTooltip]="'DASHBOARD_NEW_TASK_DESCRIPTION' | translate"
      matTooltipClass="formatted-tooltip"
      [disabled]="!selectedDashboard?.id"
      (click)="openNewTaskDialog()"
    >
      <mat-icon>add</mat-icon>
      <span>{{'NEW_TASK' | translate}}</span>
    </button>
  </div>
</div>

<div class='dashboard-card' *ngFor='let card of selectedDashboard?.dashboardcardSet.edges'>
  <app-aggregated-metrics-table
    *ngIf="card.node.card.type === 'AGGREGATED_METRIC_TABLE'"
    [cardId]='card.node.card.id'
    [cardTitle]="card.node.card.title"
    [cardDescription]='card.node.card.description'
    [dashboardItem]='selectedDashboard'
    [metricGroupForOtherProblemId]='card.node.metricGroupForOtherProblem?.id'
  ></app-aggregated-metrics-table>

  <app-promises-table
    *ngIf="card.node.card.type === 'PROMISES_TO_CLIENT_MATRIX'"
    [dashboardId]="selectedDashboard?.id"
    [dashboardMembers]='dashboardMembers'
    [includeFromChildDashboards]="card.node.card.includeFromChildDashboards"
  ></app-promises-table>

  <app-metric-table
    *ngIf="card.node.card.type === 'METRIC_TABLE'"
    [cardId]='card.node.card.id'
    [cardTitle]="card.node.card.title"
    [cardDescription]='card.node.card.description'
    [dashboardItem]='selectedDashboard'
    [escalationDashboard]='selectedDashboard?.escalateTo'
    [metricGroupForOtherProblemId]='card.node.metricGroupForOtherProblem?.id'
  ></app-metric-table>

  <app-weekly-barchart-card
    *ngIf="card.node.card.type === 'WEEKLY_BARCHART'"
    [cardId]='card.node.card.id'
    [cardTitle]="card.node.card.title"
    [dashboardItem]='selectedDashboard'
    [barchartGoal]='card.node.barchartGoal'
    [barchartCardId]="card.node.id"
  ></app-weekly-barchart-card>

  <app-overdue-tasks-table
    *ngIf="card.node.card.type === 'OVERDUE_TASK_TABLE'"
    [dashboardId]='selectedDashboard?.id'
    [cardDescription]='card.node.card.description'
    [filterTaskCategoriesKeys]="card.node.card.filterTaskCategoriesKeys"
    [excludeTaskCategoriesKeys]="card.node.card.excludeTaskCategoriesKeys"
    [excludeStatuses]="card.node.card.excludeTaskStatuses"
    #taskOverdueTable
  ></app-overdue-tasks-table>

  <app-meeting-table
    *ngIf="card.node.card.type === 'CLIENT_MEETING_TASKS'"
    [cardTitle]='card.node.card.title'
    [cardDescription]='card.node.card.description'
    [dashboardMembers]='dashboardMembers'
    [dashboardId]='selectedDashboard?.id'
    [filterTaskCategoriesKeys]="card.node.card.filterTaskCategoriesKeys"
    [excludeTaskCategoriesKeys]="card.node.card.excludeTaskCategoriesKeys"
    [excludeStatuses]="card.node.card.excludeTaskStatuses"
    [includeFromChildDashboards]="card.node.card.includeFromChildDashboards"
    #meetingOverdueTable
  ></app-meeting-table>

  <app-dynamic-dashboard-tasks-table
    *ngIf="card.node.card.type === 'DYNAMIC_TASKS_TABLE'"
    [cardTitle]='card.node.card.title'
    [cardDescription]='card.node.card.description'
    [additionalFilters]='card.node.card.additionalFilters'
    [dashboardId]='selectedDashboard?.id'
    [filterTaskCategoriesKeys]="card.node.card.filterTaskCategoriesKeys"
    [excludeTaskCategoriesKeys]="card.node.card.excludeTaskCategoriesKeys"
    [excludeStatuses]="card.node.card.excludeTaskStatuses"
    [displayColumnsJSON]="card.node.card.displayColumns"
    [lookForwardDays]='card.node.card.lookForwardDays'
    [includeFromChildDashboards]="card.node.card.includeFromChildDashboards"
  ></app-dynamic-dashboard-tasks-table>
</div>
